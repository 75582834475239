.headerRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: transparent;
}

.headerRowBis {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: transparent;
}

.iconContainer {
    cursor: pointer;
    width: 50px;
}

.basketWrapper {
    position: relative;
}

.badgeWrapper {
    position: absolute;
    top: 0;
    right: 18%;
    width: 40%;
    align-items: center;
}

.badge {
    padding: 10%;
    background-color: #e12c2c;
    color: #fff;
    border-radius: 100%;
    align-items: center;
}

.badgeText {
    text-align: center;
    font-weight: bold;
    font-size: 12px;
}