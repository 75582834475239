.container {
    height: 40px;
    background-color: #FF885A;
    color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
    cursor: pointer;
    padding-left: 40px;
    padding-right: 40px;
}

.containerDisabled {
    height: 40px;
    background-color: #e8e5e4;
    color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
    cursor: pointer;
    padding-left: 40px;
    padding-right: 40px;
}