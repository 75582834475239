.container {
    width: 100%;
    min-height: 100vh;
    background-color: #1A4777;
    padding-bottom: 5rem;
}

.homeTitleTxt {
    text-align: center;
    margin: 0%;
    color: #fff;
}

.carrouselContainer {
    display: flex;
    overflow-x: scroll;
    width: 100%;
}
.carrouselContainer::-webkit-scrollbar{
    display: none;
}

.carrouselItem {
    flex-shrink: 0;
    width: 85%;
    height: 200px;
    border-radius: 25px;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 20px;
    /* margin-bottom: 20px; */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}
.wrapperContainer{
    display: flex;
    flex-direction: column;
    padding: 10px;
}
.productCardWrapper{
    /* padding-top: 20px; */
    margin-bottom: 3rem;
        margin-left: 5px;
        margin-right: 5px;

}
.productContainerRow{
    display: flex;
    flex-direction: row;
    justify-content:center;
    align-items: flex-start;
    margin-bottom: 20px;
}
.separatorWrapper{
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.buttonWrapper{
    display: flex;
    justify-content: center;
    margin-top: 6%
}
@media (min-width: 768px) {
    
}