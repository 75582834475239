.cardContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #FAFAFA;
    align-items: center;
    border-radius: 10px;
    margin-left: 5%;
    margin-right: 5%;
    padding-top: 20px;
    /* padding-bottom: 5px; */
    padding-left: 15px;
    padding-right: 15px;
}

.firstContentRow{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}
.choicesRow{
    display: flex;
    flex-direction: row;
    /* width: 100%; */
}
.secondContentRow{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* margin-top: 5px; */
    width: 100%;
}
.contentContainerColumn{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 5px;
    width: 100%;
}
.choiceItemContainer{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-left: 5px;
    margin-right: 5px;
}
.choiceText{
    font-size: 8px;
    color: #1C008E;
    margin-bottom: 5px;
}
.priceText{
    font-size: 14px;
    color: #1C008E;
}
.imageContainer{
   display: flex;
    width: 75px;
    height: 70px;
    border-radius: 50%;
    background-color: rgba(212, 212, 210, 0.56);
    justify-content: center;
    align-items: center;
    font-size: 10px;
}

.chooseButton{
display: flex;
width: 61px;
height: 25px;
padding: 5px 13px 5px 13px;
justify-content: center;
align-items: center;
border-radius: 5px;
background-color: #FAFAFA;
box-shadow: 1px 1px 2px 0px #121D7D26;
font-size: 12px;
color: #FF885A;
cursor: pointer;
}