.container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #1A4777;
    color: #fff;
    padding: 40px;
}

.restauName {
    margin: 0%;
    padding: 0%;
    margin-top: 20px;
}

.subTitle {
    margin: 0%;
    padding: 0%;
    margin-top: 5px;
}

.checkboxGroupContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 20px;
}

.welcomeTxt {
    text-align: center;
    margin-top: 70px;
}

.selectTableContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.selectTableTitle {
    margin-top: 50px;
    text-align: center;
    color: #FF885A;
}