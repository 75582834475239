.recapContainer{
    display: flex;
    flex-direction: column;
    background-color: #fff;
    /* height: 70%; */
    /* width: 100%; */
    padding-left: 15px;
    padding-right: 15px;
    /* padding-bottom: 20px; */
}



.recapcmd{
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 15px;
    margin-top: 15px;
    color: #5C5B5E;
}

.pricingRow{
    display: flex;
    justify-content: flex-start;
    gap: 30%;
}
.titleText{
    display: flex;
    width: 100%;
    border-bottom: solid 0.5px #E5E5E5;
    color: #5C5B5E;
    font-size: 11px;
    padding-bottom: 5px;
    margin-bottom: 10px;
}
.commands{
    color: #5C5B5E;
    font-size: 11px;
    margin-bottom: 5px;
}
.priceText{
    color: #5C5B5E;
    font-size: 11px;
    margin-bottom: 5px;
}
.priceItem{
    display: flex;
    width: 100%;
    color: #5C5B5E;
    font-size: 11px;
    margin-bottom: 5px;
}
.separator{
    display: flex;
    width: 100%;
    border-bottom: solid 0.5px #E5E5E5;
    margin-bottom: 10px;
}

.totalRow{
    display: flex;
    justify-content: flex-end;
    font-size: 18px;
    color: #5C5B5E;
    font-weight:500;
    margin-top: 20px;
}
.paymentMode{
    display: flex;
    justify-content: flex-start;
    font-size: 16px;
    color: #1C008E;
    margin-top: 20px;
    font-weight: 600;
}

.row{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}
