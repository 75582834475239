.basketContainer {
    background-color: #000000;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 120px;
    height: 30px;
    border-radius: 20px;
    cursor: pointer;
}

.basketContainer p {
    margin: 0%;
    color: #ffffff;
    font-size: 12px;
}