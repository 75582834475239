.container {
    height: 40px;
    border: transparent;
    outline: none;
    background-color: #EEEEEE;
    color: #545454;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 40px;
    font-size: 14px;
    font-weight: 600;
}