.container {
    background-color: #1A4777;
    width: 100%;
    min-height: 100vh;
    padding-top: 50px;
    padding-bottom: 20px;
}

.progressBar {
    width: 90px;
    height: 5px;
    border-radius: 5px;
    background-color: #fff;
    overflow: hidden;
}
  
.fill {
    width: 0%;
    height: 100%;
    background-color: #34A853;
    animation: fillAnimation 2s linear infinite;
}

.disabledProgressBar {
    width: 90px;
    height: 5px;
    border-radius: 5px;
    background-color: #fff;
}

.enabledProgressBar {
    width: 90px;
    height: 5px;
    border-radius: 5px;
    background-color: #34A853;
}

.stepsContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.StepTitle {
    margin: 0%;
    margin-bottom: 5px;
    text-align: center;
    color: #fff;
}

.StepSubTitle {
    margin: 0%;
    margin-bottom: 20px;
    text-align: center;
    color: #E6E6E6;
    font-style: italic;
}

.orderDetailsContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    margin-top: 10px;
}

.orderDetailsLeftContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.orderDetailsRightContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.orderDetailsTxt {
    margin: 0%;
    font-size: 16px;
    color: #D1D1D1;
    margin-bottom: 5px;
}

.recapOrderContainer {
    background-color: #fff;
    width: 100%;
    padding-bottom: 20px;
}

.imgStepContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}
.buttonContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

@keyframes fillAnimation {
    0% {
      width: 0%;
    }
    50% {
      width: 100%;
    }
    100% {
      width: 0%;
    }
}

@media (min-width: 1024px) {
    
}