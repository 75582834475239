.container {
    background-color: #1A4777;
    width: 100%;
    min-height: 110vh;
    padding-bottom: 30px;
}
.cardWrapper{
    margin-top: 20px;
    margin-bottom: 20px;
}
.categoriesContainer {
    /* width: 100%; */
    padding: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #E5E5E545;
    display: flex;
    align-items: center;
    overflow-x: scroll;
}
.productCardWrapper{
    margin-top: 20px;
    margin-bottom: 3rem;
}
.productContainerRow{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
}
.categoryItemActive {
    background-color: #FF885A;
    color: #fff;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 20px;
    margin: 5px;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
}
.categoryItemActive label {
    white-space: nowrap;
}   
.categoryItemInActive {
    background-color: #fff;
    color: #FF885A;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 20px;
    margin: 5px;
    font-size: 12px;
    font-weight: 600;
    cursor:not-allowed;
}
.categoryItemInActive label {
    white-space: nowrap;
} 

.banner{
    display: flex;
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 200px;
}

.category{
    display: flex;
    position: absolute;
    justify-content: center;
    height: 20px;
    font-size: 17px;
    color: #fff;
    font-weight: 400;
    bottom: 0;
    margin-bottom: 5px;
    margin-left: 5px;
    background-color: #1a4777b8;
   padding: 12px;
   padding-top: 10px;
   padding-bottom: 10px;
   border-radius: 10px;
}

@media (min-width: 768px) {
    
}

@media (min-width: 1024px) {
    
}