.container {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 10px;
}

.container label {
    margin-left: 10px;
    color: #fff;
    font-size: 12px;
}

.active {
    width: 20px;
    height: 20px;
    border-radius: 5px;
    background-color: #1C008E;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.inactive {
    width: 18px;
    height: 18px;
    border-radius: 5px;
    border: solid 2px #1C008E;
    background-color: #fff;
}