.productCardWrappeer{
    display: flex;
    flex-direction: column;
    justify-content:space-between;
    align-items: center;
    align-content: space-around;
    width: 110px;
    height: 135px;


}
.productCard{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: #FAFAFA;
    width: 100%;
    height: 100%;
    padding-bottom: 20px;


}
.choiceText{
    font-size: 12px;
    color: #1A4777;
    margin-bottom: 5px;
    font-weight: 500;
}
.productName{
    font-size: 12px;
    color: #FAFAFA;
    margin-bottom: 10px;
}
.imageContainer{
   display: flex;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    /* background-color: yellow; */
    justify-content: center;
    align-items: center;
    font-size: 10px;
    margin-top: 15px;
    margin-bottom: 10px;
}

.chooseButton{
display: flex;
margin-top: -13px;
width: 72px;
height: 24px;
padding: 5px 13px 5px 13px;
justify-content: center;
align-items: center;
border-radius: 8px;
background-color: #FAFAFA;
box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
font-size: 12px;
color: #FF885A;
cursor: pointer;

}