.container {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 10px;
}

.container label {
    margin-left: 10px;
    color: #fff;
    font-size: 12px;
}

.active {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.activeCenter {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background-color: #1C008E;
}

.inactive {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    border: solid 2px #fff;
}