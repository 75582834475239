.container {
    width: 100%;
    min-height: 100vh;
    background-color:#1A4777;
    padding-bottom: 20px;
}

.pageTitle {
    margin: 0%;
    text-align: center;
    color: #fff;
    margin-top: 20px;
}

.paymentSwitchContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
}

.paymentSwitchSubContainer {
    z-index: 1;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 40px;
    background-color: #ffffff;
    -webkit-box-shadow: 0px 7px 14px -1px rgba(0,0,0,0.7); 
    box-shadow: 0px 7px 14px -1px rgba(0,0,0,0.7);
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
}

.paymentSwitchActiveButton {
    font-size: 10px;
    background-color: #FF885A;
    color: #fff;
    font-weight: 600;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 40px;
    cursor: pointer;
    transition: 0.5s;
}

.paymentSwitchInActiveButton {
    font-size: 10px;
    background-color: #fff;
    color: #FF885A;
    font-weight: 600;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 40px;
    cursor: pointer;
    transition: 0.5s;
}

.paymentSwitchChildContainer {
    width: 80%;
    background-color: #fff;
    padding: 20px;
    border-radius: 20px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.warningTxt {
    text-align: center;
    margin-left: 10%;
    margin-right: 10%;
    color: #A8A8A8;
}

.mobilPaymentContainer {
    padding-left: 10%;
    padding-right: 10%;
}

.mobilPaymentTxtTitle {
    color: #fff;
    text-align: center;
}
.mobilPaymentSubContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.itemContainer{
    width: 40%;
}