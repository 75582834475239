.container {
    /* width: 100%; */
    min-height: 100vh;
    background-color: #1A4777;
    padding-bottom: 20px;

}
.titleRow{
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    margin-top: 20px;
}

.titleText{
    color: #fff;
    font-size: 19px;
    margin-right: 30px;
    margin-left: 30px;
}
.buttonContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}
.validationButon{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 33px;
    width: 25%;
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #FF885A;
    color:#fff;
    font-size: 17px;
    font-weight: 400;
    border-radius: 40px;
    cursor: pointer;
}
