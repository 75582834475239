.container {
    background-color: #1A4777;
    min-height: 100vh;
    width: 100%;
    padding-bottom: 20px;
}

.AppHeaderContainer {
    position: absolute;
    width: 100%;
    z-index: 2;
}

.productHeadContainer {
    height: 300px;
    width: 100%;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.darkScreen {
    height: 300px;
    width: 100%;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: #0000003f;
    position: absolute;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.productName {
    margin: 0%;
    text-align: center;
    color: #fff;
}

.priceContainer {
    background-color: #FF885A80;
    color: #fff;
    font-weight: 700;
    font-size: 16px;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 20px;
    position: absolute;
    right: 0%;
    top: 260px;
}

.descriptionContainer {
    margin: 20px;
}

.descriptionTxt {
    color: #fff;
    font-size: 12px;
    text-align: justify;
}

.sideDishContainer {
    padding: 20px;
}

.sideDishSubContainer {
    display: flex;
    align-items: center;
}

.sideDishTxtContainer {
    font-size: 12px;
    color: #fff;
    background-color: #ffffff45;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 20px;
}

.line {
    flex: 1;
    margin-left: 10px;
    border: solid 1px #B3B3B3;
}

.sideDishItemList {
    width: 80%;
    margin-left: 20%;
}

.sideDishItem {
    border-bottom: solid 1px #E5E5E545;
    margin-top: 20px;
}

.extraSideDishItem {
    border-bottom: solid 1px #E5E5E545;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.extraSideDishItemPrice {
    color: #fff;
    font-size: 10px;
}

.addInBasketBtn {
    font-weight: 600;
    padding: 10px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    width: 60%;
    margin-left: 15%;
}

@media (min-width: 768px) {
    
}

@media (min-width: 1024px) {
    
}