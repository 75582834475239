.container {
    flex: 1;
    background-color: #fff;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
}

.leftContentContainer {
    display: flex;
    align-items: center;
    width: 70%;
}

.productImg {
    width: 60px;
    height: 60px;
    border-radius: 60px;
}

.productDetailsContainer {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
}

.productName {
    font-size: 12px;
    color: #1C008E;
    margin-bottom: 10px;
}

.productPrice {
    color: #707C9B;
    font-size: 10px;
}

.rightContentContainer {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.down {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    background-color: #fff;
    color: #FF885A;
    border: solid 1px #FF885A;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.down label {
    padding: 0%;
    margin: 0%;
    font-size: 12px;
}

.quantity {
    font-size: 12px;
    color: #1C008E;
}

.up {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    background-color: #FF885A;
    color: #fff;
    border: solid 1px #FF885A;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.up label {
    padding: 0%;
    margin: 0%;
    font-size: 12px;
}