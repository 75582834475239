.container {
  background-color: #1A4777;
  width: 100%;
  min-height: 110vh;
  padding-top: 20px;
}

.subContainer {
  position: fixed;
  overflow: scroll;
  width: 100%;
}

.BasketheadContainer {
  padding: 10px;
  border-bottom: solid 1px #fff;
  border-bottom-style: dashed;
}

.BasketTitle {
  color: #fff;
  margin: 0%;
}

.buttonContainer {
  width: 100%;
  display: flex;
  justify-content: end;
  margin-top: 20px;
}

.productBasketContainer {
  padding: 10px;
  border-bottom: solid 1px #fff;
  border-bottom-style: dashed;
  margin-bottom: 20px;
  overflow-y: scroll;
  max-height: 30%;
}

.productItemContainer {
  margin-bottom: 10px;
}

.suggestionproductContainer {
  width: 100%;
  bottom: 0%;
  /* position: fixed; */
  background-color: #1A4777;
}

.suggestionproductTxt {
  color: #fff;
  margin-left: 10px;
}

.suggestionproductSubContainer {
  display: flex;
  overflow-x: scroll;
  width: 100%;
  height: 250px;
}

.suggestionWrapper {
  margin-bottom: 100px;
}

.suggestionproductSubContainer::-webkit-scrollbar {
  display: none;
}

.cardItemContainer {
  margin-left: 20px;
}

.bottomContainer {
  background-color: #fff;
  padding: 10px;

}

.bottomContainerWrapper {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.bottomSubContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #1A4777;
  font-weight: 700;
}

@media (min-width: 768px) {}

@media (min-width: 1024px) {}